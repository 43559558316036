let slidessrc = [
    {
      type: "youtube",
      src: "https://www.youtube.com/embed/L5oxYg4rMwo?si=XA7WptLOnDCZgUbq",
      title: "Entrevistas - 1",
      width: 2160,
      height: 1215
    },
    {
      type: "youtube",
      src: "https://www.youtube.com/embed/Y3MgMO6zmn4?si=4gtNdjitJ0U_x3ds",
      title: "Entrevistas - 2",
      width: 2160,
      height: 1215
    },
    {
      type: "youtube",
      src: "https://www.youtube.com/embed/hKiVDVdeszo?si=OohXRBfPj4UVoC3P",
      title: "Entrevistas - 3",
      width: 2160,
      height: 1215
    }
  ];
  export default slidessrc; //slidessrc pues es la fuente real